<template>
  <div class="lay-head">
    <div class="lay-left">
      <router-link class="logo" to="/">钢材伙伴</router-link>
    </div>
    <div class="lay-right">
      <div class="flex flex-y-center">
        <span>业务后台</span>
        <span class="m-x-5">丨</span>
        <el-link class="logout" type="primary" @click.native="logout">退出登录</el-link>
      </div>
      <div>
        <el-dropdown>
          <span>
            <span>{{ $store.getters.getLoginAccount.nick_name }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="edit">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <b-popup-account
      v-if="isShowPopupAccount"
      :show.sync="isShowPopupAccount"
      :detailId="$store.getters.getLoginAccount.id"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowPopupAccount: false
    }
  },
  methods: {
    // 修改
    edit() {
      this.isShowPopupAccount = true
    },
    // 退出
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.lay-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .lay-left {
    width: @navWidth;
    box-sizing: border-box;
    .logo {
      display: block;
      padding-left: 32px;
      color: #fff;
      text-decoration: none;
      background: #26282c url(https://gcvip168.com/wx/pc/logo.png) 10px center no-repeat;
      background-size: 18px;
      font-size: 12px;
      width: @navWidth;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
    }
  }
  .lay-right {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    .logout {
      font-size: 16px;
    }
  }
}
</style>
