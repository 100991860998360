<template>
  <el-dialog title="检测标准" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn"
        :data="selfData.testList"
      />
      <div class="m-t-20 m-b-10">图纸</div>
      <e-upload-image :list.sync="selfData.testImg" :disabled="disabled" />
      <div class="m-t-20 m-b-10">附言</div>
      <el-input type="textarea" size="mini" :disabled="disabled" v-model="selfData.testRemark" />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupCancel">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        testList: [],
        testImg: [],
        testRemark: ''
      },
      tableColumn: [
        {
          title: '序号',
          type: 'seq'
        },
        {
          title: '检测项目',
          field: 'test_name'
        },
        {
          title: '检测方法',
          field: 'test_action'
        },
        {
          title: '检测标准',
          field: 'test_rule'
        },
        {
          title: '检测比例',
          field: 'test_scale'
        },
        {
          title: '参考标准',
          field: 'reference_standard'
        },
        {
          title: '备注',
          field: 'remark'
        }
      ],
      disabled: true
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {
        rowConfig: {
          isHover: false,
          isCurrent: false
        },
        editConfig: {
          beforeEditMethod() {
            return !this.disabled
          }
        },
        keyboardConfig: {
          isEdit: !this.disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getListData()
  },
  methods: {
    // 获取列表数据
    getListData() {
      let params = {
        stock_id: this.data.id_stock
      }
      if (this.data.process_id) {
        params.process_id = this.data.process_id
      }
      this.$api({
        method: 'get',
        url: '/admin/gcvip/stock/detailByTestStock',
        params
      }).then((res) => {
        if (res.data.state === 'ok') {
          let data = res.data.data
          if (this.data.type_name === '原料') {
            this.selfData.testList = data.test_list
            this.selfData.testImg = data.material_photo_list
            this.selfData.testRemark = data.material_remark
          }
          if (this.data.type_name === '加工') {
            this.selfData.testList = data.process.test_list
            this.selfData.testImg = data.process.picture_list
            this.selfData.testRemark = data.process.picture_text
          }
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped></style>
