<template>
  <el-dialog :visible.sync="selfShow" :title="id ? '编辑客户' : '新增客户'" width="780px">
    <el-form ref="refForm" size="mini" label-width="130px" :model="detailData" :rules="rules">
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="客户企业名称" prop="customer_name">
            <el-input clearable v-model="detailData.customer_name" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="所属业务" prop="saler_id">
            <el-select
              style="width: 100%"
              placeholder="可搜索查询"
              default-first-option
              clearable
              filterable
              v-model="detailData.saler_id"
            >
              <el-option
                v-for="(item, index) in saleListData"
                :key="index"
                :label="item.nick_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="联系人">
            <el-input clearable v-model="detailData.linkman" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="提成比例">
            <el-input clearable v-model="detailData.commission_rate">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="联系人电话">
            <el-input clearable v-model="detailData.phone" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="联系人微信">
            <el-input clearable v-model="detailData.wei_xin" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="24" :md="24">
          <el-form-item label="客户地址">
            <el-input clearable v-model="detailData.address" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="24" :md="24">
          <el-form-item label="客户需求">
            <el-input
              v-model="detailData.customer_demand"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="旺旺号">
            <el-input clearable v-model="detailData.alibaba" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="所属行业">
            <el-input clearable placeholder="暂不使用" disabled />
            <!-- <el-input clearable v-model="detailData.suo_shu_hang_ye" disabled /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="客户来源">
            <el-select style="width: 100%" placeholder="请选择" v-model="detailData.lai_yuan">
              <el-option
                v-for="(item, index) in sourceListData"
                :key="index"
                :label="item.key"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="客户年限">
            <el-input disabled v-model="detailData.customer_life" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="法人">
            <el-input clearable v-model="detailData.certificate_code" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="法人电话">
            <el-input clearable v-model="detailData.certificate_phone" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="开户银行">
            <el-input clearable v-model="detailData.deposit_bank" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="银行账号">
            <el-input clearable v-model="detailData.deposit_bank_number" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :md="12">
          <el-form-item label="统一社会信用代码">
            <el-input clearable v-model="detailData.tax_id" />
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="12">
          <el-form-item label="状态">
            <el-switch active-value="激活" inactive-value="禁用" v-model="detailData.state" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="图片附件">
            <e-upload-image size="100px" :list.sync="detailData.photo_list" isDelete />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 隐藏字段 -->
    <el-form v-if="false">
      <el-form-item label="曾用名">
        <el-input clearable readonly v-model="detailData.used_name" />
      </el-form-item>
      <el-form-item label="传真">
        <el-input clearable v-model="detailData.fax" />
      </el-form-item>
      <el-form-item label="月任务">
        <el-input-number :controls="false" :precision="2" v-model.number="detailData.month_task" />
      </el-form-item>
      <el-form-item label="年任务">
        <el-input-number :controls="false" :precision="2" v-model.number="detailData.year_task" />
      </el-form-item>
      <el-form-item label="经销期限">
        <el-date-picker type="date" placeholder="选择日期" v-model="detailData.period_distribution">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="信用额度">
        <el-input-number
          :controls="false"
          :precision="2"
          clearable
          v-model.number="detailData.credit_lines"
        />
      </el-form-item>
      <el-form-item label="账期">
        <el-input type="number" v-model.number="detailData.payment_days">
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item label="月结日期：每月" label-width="120px">
        <el-input type="number" v-model.number="detailData.monthly_statement_date">
          <template slot="append">号(零代表当月月末)</template>
        </el-input>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    id: {
      type: [String, Number],
      default: null
    },
    salerName: {
      type: String,
      default: ''
    },
    salerId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      selfShow: false,
      detailData: {
        id: '',
        customer_name: '', // 客户企业名称
        saler_id: '', // 所属业务id
        sales_man: '', // 所属业务
        linkman: '', // 联系人
        commission_rate: 0, // 提成比例
        phone: '', // 联系人电话
        wei_xin: '', // 联系人微信
        address: '', // 客户地址
        customer_demand: '', // 客户需求
        alibaba: '', // 旺旺号
        suo_shu_hang_ye: '', // 所属行业
        lai_yuan: '', // 客户来源
        customer_life: '', // 客户年限
        certificate_code: '', // 法人
        certificate_phone: '', // 法人电话
        deposit_bank: '', // 开户银行
        deposit_bank_number: '', // 银行账号
        tax_id: '', // 统一社会信用代码
        photo_list: [], // 图片附件
        state: '激活', // 状态

        // 隐藏字段
        used_name: '', // 曾用名
        fax: '', // 传真
        month_task: 0, // 月任务
        year_task: 0, // 年任务
        period_distribution: '', // 经销期限
        credit_lines: '', // 信用额度
        payment_days: 0, // 账期
        monthly_statement_date: 0 // 月结日期：每月
      },
      rules: {
        customer_name: [{ required: true, message: '请输入客户企业名称' }],
        saler_id: [{ required: true, message: '请选择所属业务' }]
      },

      sourceListData: [
        {
          key: '电商',
          val: '电商'
        },
        {
          key: '线下',
          val: '线下'
        }
      ],
      saleListData: []
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    'detailData.saler_id': {
      handler(newVal) {
        this.saleListData.forEach((item) => {
          if (item.id === newVal) {
            this.detailData.sales_man = item.nick_name
          }
        })
      },
      deep: true
    }
  },
  mounted() {
    this.selfShow = this.show
    if (this.salerName) this.detailData.sales_man = this.salerName
    if (this.salerId) this.detailData.saler_id = this.salerId
    if (this.id) this.getDetailData()
    this.getSaleListData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.$api({
        method: 'post',
        url: '/admin/customers/editVue',
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.status === 200) {
          this.detailData = res.data
        }
      })
    },
    // 获取业务员列表
    getSaleListData() {
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          filterData: {
            role_name: '业务员'
          }
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.saleListData = res.data.page.list
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/customers/saveOrUpdate',
            data: this.detailData
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('保存成功')
              this.$emit('success')
              this.selfShow = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
