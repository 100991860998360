<template>
  <div class="b-detail-nav">
    <router-link v-for="(item, index) in list" :key="index" :to="item.path" class="nav-item">
      {{ item.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      list: [
        {
          name: '核算单详情',
          path: `/sale/verify/detail?id=${this.id}`,
          control: [''],
          isShow: false
        },
        {
          name: '报价单详情',
          path: `/sale/quote/detail?id=${this.id}`,
          control: [''],
          isShow: false
        },
        {
          name: '合同详情',
          path: `/sale/contract/detail?id=${this.id}`,
          control: [''],
          isShow: false
        },
        {
          name: '财务详情',
          path: `/finance/payin/detail?id=${this.id}`,
          control: [''],
          isShow: false
        }
        // {
        //   name: '生产流程/标准',
        //   path: '/shengchan',
        //   control: [''],
        //   isShow: false
        // },
        // {
        //   name: '采购单详情',
        //   path: '/caigou',
        //   control: [''],
        //   isShow: false
        // },
        // {
        //   name: '送货单详情',
        //   path: '/songhuodan',
        //   control: [''],
        //   isShow: false
        // },
        // {
        //   name: '本厂加工详情',
        //   path: '/benchang',
        //   control: [''],
        //   isShow: false
        // },
        // {
        //   name: '委外加工详情',
        //   path: '/weiwai',
        //   control: [''],
        //   isShow: false
        // },
        // {
        //   name: '入库详情',
        //   path: '/ruku',
        //   control: [''],
        //   isShow: false
        // },
        // {
        //   name: '出库详情',
        //   path: '/chuku',
        //   control: [''],
        //   isShow: false
        // }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.b-detail-nav {
  width: 82px;
  height: 100%;
  background: @colorGrayF;
  .nav-item {
    display: block;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    color: @colorBlack;
    text-decoration: none;
    border-left: 2px solid transparent;
    &.router-link-exact-active {
      color: @colorBlue;
      background: @colorShallowBlue;
      border-color: @colorBlue;
    }
  }
}
</style>
