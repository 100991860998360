<template>
  <div class="page-main">
    <el-dialog
      width="500px"
      :title="detailId ? `编辑${userName}` : `新增${userName}`"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" label-width="130px" :rules="accountFormRules" :model="accountForm">
        <el-form-item v-if="!isTourist" label="所属部门:" prop="bu_men_id">
          <el-cascader
            ref="refBumen"
            style="width: 100%"
            v-model="accountForm.bu_men_id"
            :options="bumenList"
            :props="bumenProps"
            :show-all-levels="false"
            :disabled="!!detailId || isSelf"
            size="mini"
            clearable
            @change="bumenUpdate"
          />
        </el-form-item>
        <el-form-item label="姓名:" prop="nick_name">
          <el-input size="mini" v-model="accountForm.nick_name" :disabled="isSelf" />
        </el-form-item>
        <el-form-item label="手机:" prop="user_name">
          <el-input size="mini" v-model="accountForm.user_name" :disabled="isSelf" />
        </el-form-item>
        <el-form-item label="密码:" prop="pass_word">
          <el-input
            v-model="accountForm.pass_word"
            size="mini"
            show-password
            auto-complete="new-password"
            :placeholder="detailId ? '留空不修改密码，必须包含字母+数字，6~15位' : ''"
          />
        </el-form-item>
        <el-form-item v-if="$tool.hasControl(['/account/save'])" label="岗位权限:" prop="roles">
          <el-select
            v-model="accountForm.roles"
            size="mini"
            multiple
            style="width: 100%"
            :disabled="isSelf"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="$tool.hasControl(['/account/save']) && !isTourist" label="岗位名称:">
          <el-input size="mini" v-model="accountForm.job_name" :disabled="isSelf" />
        </el-form-item>
        <el-form-item v-if="!isTourist" label="提成比例:" prop="commission_rate">
          <el-input
            size="mini"
            type="number"
            v-model="accountForm.commission_rate"
            :disabled="isSelf"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="!isBoss && !isTourist" label="设为管理员:">
          <el-switch
            :active-text="accountForm.is_admin ? '是' : '否'"
            v-model="accountForm.is_admin"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="popupCancel">取消</el-button>
        <el-button type="primary" size="mini" @click="popupConfirm"> 确认 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detailId: {
      type: [Number, String],
      default: ''
    },
    activeObj: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      selfShow: false,
      isTourist: false, // 是否是游客
      accountForm: {
        id: '', // id员工
        bu_men_id: [], // 所属部门id
        bu_men_name: [], // 所属部门
        nick_name: '', // 姓名
        user_name: '', // 手机
        pass_word: '', // 密码
        roles: [], // 岗位权限
        job_name: '', // 岗位名称
        commission_rate: '', // 提成比例
        is_admin: false // 是否管理员
      },
      bumenList: [],
      bumenProps: { value: 'id', label: 'name', checkStrictly: true },
      roleList: [],
      accountFormRules: {
        bu_men_id: [{ required: true, message: '请选择所属部门', trigger: 'blur' }],
        nick_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        user_name: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        pass_word: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: '密码长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ],
        roles: [{ required: true, message: '请选择岗位', trigger: 'blur' }]
      }
    }
  },
  computed: {
    isBoss() {
      let result = this.$store.getters.getLoginAccount.is_boss
      return result
    },
    isSelf() {
      let result = this.detailId === this.$store.getters.getLoginAccount.id
      return result
    },
    userName() {
      return this.isTourist ? '游客' : '员工'
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    if (this.detailId) {
      this.getDetailData()
    } else {
      if (this.activeObj) {
        if (this.activeObj.is_folder) {
          //  部门
          this.accountForm.bu_men_id = this.activeObj.id
          this.accountForm.bu_men_name = this.activeObj.name
        } else {
          // 员工
          this.accountForm.bu_men_id = this.activeObj.parent_id
          this.accountForm.bu_men_name = this.activeObj.parent_name
        }
      }
    }
    this.getBumenList()
    this.getRoleList()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.accountFormRules.pass_word[0].required = false
      this.$api({
        method: 'post',
        url: '/admin/account/editVue',
        params: {
          id: this.detailId
        }
      }).then((res) => {
        if (res.status === 200) {
          let data = res.data.account
          this.accountForm.id = data.id
          this.accountForm.bu_men_id = data.bu_men_id
          this.accountForm.bu_men_name = data.bu_men_name
          this.accountForm.nick_name = data.nick_name
          this.accountForm.user_name = data.user_name
          this.accountForm.roles = data.role_name.map((item) => item.id)
          this.accountForm.job_name = data.job_name
          this.accountForm.commission_rate = data.commission_rate
          this.accountForm.is_admin = data.is_admin
        }
      })
    },
    // 预处理函数：移除空的 children
    removeEmptyChildren(data) {
      return data.map((item) => {
        // 深拷贝当前节点，避免修改原始数据
        const newItem = { ...item }
        if (Array.isArray(newItem.children)) {
          if (newItem.children.length === 0) {
            delete newItem.children // 删除空 children
          } else {
            newItem.children = this.removeEmptyChildren(newItem.children) // 递归处理子节点
          }
        }
        return newItem
      })
    },
    // 获取部门数据列表
    getBumenList() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bumen/list',
        data: {
          search: 'bu_men'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.bumenList = this.removeEmptyChildren(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取岗位数据列表
    getRoleList() {
      // 获取RoleList
      this.$api({
        method: 'post',
        url: '/admin/account/assignRolesListVue'
      })
        .then((res) => {
          if (res.status === 200) {
            this.roleList = res.data.roleList
          }
        })
        .catch(() => {
          return
        })
    },
    // 部门更新
    bumenUpdate() {
      let bumenNodes = this.$refs.refBumen.getCheckedNodes()
      if (bumenNodes.length) {
        this.accountForm.bu_men_id = bumenNodes[0].path
        this.accountForm.bu_men_name = bumenNodes[0].pathLabels
      } else {
        this.accountForm.bu_men_id = []
        this.accountForm.bu_men_name = []
      }
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          let url = ''
          // 判断是否超级管理员
          if (this.$tool.hasControl(['/account/save'])) {
            if (this.detailId) {
              url = '/admin/account/updateVue'
            } else {
              url = '/admin/account/saveVue'
            }
          } else {
            url = '/admin/account/myPassWord'
          }
          this.$api({
            method: 'post',
            url: url,
            data: this.accountForm
          })
            .then((res) => {
              if (res.status === 200 && res.data.state === 'ok') {
                this.$message.success(res.data.msg)
                this.$emit('success')
                this.updateRoleVersion()
                this.popupCancel()
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        } else {
          return false
        }
      })
    },
    // 更新用户权限
    updateRoleVersion() {
      this.$api({
        method: 'post',
        url: '/admin/roleVersion',
        data: {
          roleVersion: this.$store.getters.getRoleVersion
        }
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.roleVersion != null && res.data.account != null) {
            this.$store.dispatch('asyncUpdateRoleVersion', res.data.roleVersion)
            this.$store.dispatch('asyncUpdateLoginAccount', res.data.account)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  .el-form-item__content .el-input-group {
    vertical-align: middle;
  }
}
</style>
